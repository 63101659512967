import React from 'react'


const circles = () => {
    return (
        <div className="circle-animation">
            <svg id="two-circles" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <defs>
          <style dangerouslySetInnerHTML={{__html: "\n            .cls-1 {\n                fill: none;\n                stroke: blue;\n                stroke-miterlimit: 10;\n            }\n        " }} />
        </defs>
        <title>MDE</title>
        <g id="circle-two">
          <path className="cls-1" d="M161.69,47.88c19.14,36.58,5.71,81.39-30,100.08-37.93,19.84-80.53-2.88-96.42-27.33a50.47,50.47,0,0,1-2.9-5.08C19,88.73,21.69,36.76,62.37,15.48,98.08-3.21,142.54,11.3,161.69,47.88Z" />
          <path className="cls-1" d="M37.92,124.37c3.59,4.28,10.88,5.67,13.85,5.77,5.24.16,7.84-.13,11.15-1.9a84.44,84.44,0,0,0,7.9-5.08c1.17-.84,5.08-4.43,7.11-5.54,6.62-3.62,8.27,2.7,6.27,5.8-1.89,2.91-3.37,4.61-2.59,8.92.72,4,6,6.26,10.86,2.67,3.64-2.69,3.53-4.52,4.6-11.39a6.61,6.61,0,0,1,3.41-5.25c2.41-1.65,7.7-1.07,10,2.5,2.64,4.14,5.12,6.06,6.43,6.56,5.06,1.93,9.94-.16,11.68-3.89,1-2.23,3.88-6.45-4.71-11.1-5.09-2.76-3.7-7.71-1.6-9.1,2.68-1.77,8.8-3,16.61-.49,4.56,1.46,8,2.73,11.53,1.54a31.57,31.57,0,0,0,11.54-7.78,40.61,40.61,0,0,0,6.24-10.36c1.63-4.53,1.63-9.53,1.68-12.17" />
        </g>
        <g id="circle-one">
          <path className="cls-1" d="M40,145.42C20.85,108.84,34.28,64,70,45.35c37.93-19.85,80.53,2.88,96.42,27.33a49.39,49.39,0,0,1,2.9,5.08c13.34,26.81,10.68,78.79-30,100.07C103.6,196.51,59.14,182,40,145.42Z" />
          <path className="cls-1" d="M163.76,68.94c-3.59-4.29-10.87-5.68-13.84-5.77-5.25-.17-7.85.13-11.15,1.89a86.46,86.46,0,0,0-7.91,5.08c-1.17.84-5.08,4.43-7.11,5.54-6.62,3.62-8.27-2.69-6.27-5.79,1.89-2.92,3.37-4.61,2.59-8.92-.72-4-6-6.26-10.86-2.68-3.64,2.7-3.53,4.53-4.6,11.4a6.59,6.59,0,0,1-3.41,5.25c-2.41,1.65-7.7,1.06-10-2.5-2.64-4.14-5.12-6.07-6.43-6.56-5.06-1.94-9.94.16-11.67,3.89-1,2.23-3.89,6.45,4.7,11.1,5.09,2.76,3.7,7.71,1.6,9.09-2.68,1.77-8.8,3-16.61.5-4.55-1.46-8.05-2.73-11.52-1.54a31.66,31.66,0,0,0-11.55,7.77,40.94,40.94,0,0,0-6.24,10.37c-1.63,4.52-1.63,9.53-1.68,12.16" />
        </g>
      </svg>
        </div>
    )
}

export default circles